import React from 'react';
import './food.css';

import { useState, useEffect } from 'react';
import splash from '../../assets/poster_datavis/portfolio_poster_splash.png';
import motive from '../../assets/poster_datavis/portfolio_poster_motive.png';
import product_1 from '../../assets/poster_images/poster_final.png';
import product_2 from '../../assets/poster_images/3D_work.png';
import product_3 from '../../assets/poster_images/comp_datavis_poster.png';

const Food = () => {
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);


  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div>

      <div className='portfolio__food' id='food'>
        <div className='portfolio__food-content'>
          <h1 className='gradient__text__food'>EATING AT THE THI</h1>
          <h2 className='gradient__text__food'>DATAVISUALIZATION</h2>
        </div>
        <div className='portfolio__food-image animate'>
          <img src={splash} alt="splash" className='portfolio__food-image-bg' id='food-bg' />
          <img src={motive} alt="motive" className='portfolio__food-image-fg' id='food-fg' />
        </div>
      </div>

      <div className='portfolio__food'>
        <div className='portfolio__food-content'>
          <h3 className='gradient__text__food'>Project requirements</h3>
          <div>
            <p className='requirements_food' style={{textAlign: 'left'}}>Conduct survey</p>
            <p style={{textAlign: 'left'}}>Topic related to university</p>
            <p></p>
          </div>
        </div>
        <div className='portfolio__food-image animate'>
          <img src={product_1} alt="product_1" className='portfolio__food-image-product p1' id='food-p1' />
        </div>
      </div>

      <div className='portfolio__food'>
        <div className='portfolio__food-content'>
          <h3 className='gradient__text__food'>Goal</h3>
          <div>
            <p className='requirements_food' style={{textAlign: 'left'}}>Evaluate the conducted survey and visualize the most interesting results on a print poster</p>
            <p></p>
            <p></p>
          </div>
        </div>
        <div className='portfolio__food-image animate'>
          <img src={product_2} alt="product_2" className='portfolio__food-image-product p2' id='food-p1' />
        </div>
      </div>

      <div className='portfolio__food'>
        <div className='portfolio__food-content-implementation'>
          <h3 className='gradient__text__food'>Implementation</h3>
          <div className='spacer_food'>
            <p> A set of different topics was available to choose from, one of them being eating at the university. Now surveys were conducted on the different topics and the results could then be used for the individual data visualizations. <br />
              The survey on food was realized using Google’s form tool. After receiving the results all information had to be filtered for interesting, usable data. In this case the number of people and where they eat was taken and visualized. <br />
              For the visualization, a 3D model showing all  people who participated in the survey was created. This model then was arranged to show the amount of people according to their faculty and inside of each faculty the location they choose for lunch was marked through colours.</p>
          </div>
        </div>

        <div className='portfolio__food-content-implementation distance_food'>
          <div>
            <p>With the main content being the 3D model, layout options for the poster were created. <br />
              The final poster shows the rendered 3D models and a legend to the color coding.
            </p>
          </div>
        </div>

        <div className='portfolio__food-image animate'>
          <img src={product_3} alt="product_2" className='portfolio__food-image-product p2' id='food-p1' />
        </div>
      </div>

    </div>

  )

}

/* window.addEventListener('scroll', () => {
  const scrolled = window.scrollY;
  var element = document.getElementById("food-fg");
  var spl = document.getElementById("food-bg");

  if (scrolled <= 1900) {
    factor = 1;
    element.classList.remove("fade-out");
  }
  else if (scrolled > 1901) {
    element.classList.add("fade-out");
  }
}) */

export default Food