import React from 'react';
import './motivation.css';

import { useState, useEffect } from 'react';
import splash from '../../assets/motivation_images/motivation_splash_1.png';
import motive from '../../assets/motivation_images/motivation_motive.png';
import product_1 from '../../assets/motivation_images/Motivation.png';
import product_2 from '../../assets/motivation_images/Motivationsfaktoren.png';
import product_3 from '../../assets/motivation_images/Illustration_development.png';

var factor = 1;

const Motivation = () => {
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);


  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div>
      <div className='portfolio__motivation' id='motivation'>
        <div className='portfolio__motivation-image animate'>
          <img src={splash} alt="splash" className='portfolio__motivation-image-bg' id='motivation-bg' />
          <img src={motive} alt="motive" className='portfolio__motivation-image-fg' id='motivation-fg' />
        </div>

        <div className='portfolio__motivation-content'>
          <h1 className='gradient__text__motivation'>WHAT MOTIVATES YOU</h1>
          <h2 className='gradient__text__motivation'>DATAVISUALIZATION</h2>
        </div>
      </div>

      <div className='portfolio__motivation'>
        <div className='portfolio__motivation-image-product animate'>
          <img src={product_1} alt="product_1" className='p1' id='motivation-p1' />
        </div>
        <div className='portfolio__motivation-content'>
          <h3 className='gradient__text__motivation'>Project requirements</h3>
          <div>
            <p className='requirements_motivation' style={{textAlign: 'left'}}>Free choice of topic and medium of representation</p>
            <p></p>
            <p></p>
          </div>
        </div>
      </div>

      <div className='portfolio__motivation'>
        <div className='portfolio__motivation-image-product animate'>
          <img src={product_2} alt="product_2" className='p2' id='motivation-p1' />
        </div>
        <div className='portfolio__motivation-content'>
          <h3 className='gradient__text__motivation'>Goal</h3>
          <div>
            <p className='requirements_motivation' style={{textAlign: 'left'}}>Collect data on chosen topic and display it in an aestethic and understandable way</p>
            <p></p>
            <p></p>
          </div>
        </div>
      </div>

      <div className='portfolio__motivation'>
        <div className='portfolio__motivation-image-product animate'>
          <img src={product_3} alt="product_2" className='p2' id='motivation-p1' />
        </div>

        <div className='portfolio__motivation-content-implementation distance_motivation'>
          <div>
            <p>Multiple topics were explored as possible subjects for this project. The most interesting ones were reading, nature and motvation. As the topic affects every student at some point in their life, motivation was the topic of choice. <br />
              First a survey was constructed to find out about what motivates people. <br />
              The results of the survey were categorized and evaluated. Having collected all the necessary information, the medium of representation had to be selected. Thinking of ways to convey information, videos are a commonly used form as the content is pretty easy to pick up for users. <br />
              To structure the video, a script was created including most of the information to be displayed.
            </p>
          </div>
        </div>

        <div className='portfolio__motivation-content-implementation'>
          <h3 className='gradient__text__motivation'>Implementation</h3>

          <p className='spacer_motivation'>Now a big part that had to be done was finding and designing metaphors for the different motivational factors. The illustrations of the metaphors were made in illustrator and afterwards they were used in After Effects to represent the currently stated information in the recorded script. <br />
            To finish the project, the voiceover, music and animations were combined and presented in the final video clip.</p>

        </div>
      </div>

    </div>

  )

}

/* window.addEventListener('scroll', () => {
  const scrolled = window.scrollY;
  var element = document.getElementById("motivation-fg");
  var spl = document.getElementById("motivation-bg");

  if (scrolled <= 1900) {
    factor = 1;
    element.classList.remove("fade-out");
  }
  else if (scrolled > 1901) {
    element.classList.add("fade-out");
  }
}) */

export default Motivation