import React from 'react';
import './mobility.css';

import { useState, useEffect } from 'react';
import splash from '../../assets/mobility_concept/portfolio_mobility_splash.png';
import motive from '../../assets/mobility_concept/portfolio_mobility_motive.png';
import product_1 from '../../assets/mobility_images/login_screen_mobility.png';
import product_2 from '../../assets/mobility_images/map_screen_mobility.png';
import product_3 from '../../assets/mobility_images/mobility_temp.png';

var factor = 1;

const Mobility = () => {
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);


  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div>
      <div className='portfolio__mobility' id='mobility'>
        <div className='portfolio__mobility-image animate'>
          <img src={splash} alt="splash" className='portfolio__mobility-image-bg' id='mobility-bg' />
          <img src={motive} alt="motive" className='portfolio__mobility-image-fg' id='mobility-fg' />
        </div>

        <div className='portfolio__mobility-content'>
          <h1 className='gradient__text__mobility'>MOBILITY CONCEPT</h1>
          <h2 className='gradient__text__mobility'>APP DESIGN</h2>
        </div>
      </div>

      <div className='portfolio__mobility'>
        <div className='portfolio__mobility-image-product animate'>
          <img src={product_1} alt="product_1" className='portfolio__mobility-image-product' id='mobility-p1' />
        </div>
        <div className='portfolio__mobility-content'>
          <h3 className='gradient__text__mobility'>Project requirements</h3>
          <div>
            <p className='requirements_mobility' style={{textAlign: 'left'}}>Develop a concept for future mobility</p>
            <p></p>
            <p></p>
          </div>
        </div>
      </div>

      <div className='portfolio__mobility'>
        <div className='portfolio__mobility-image-product animate'>
          <img src={product_2} alt="product_2" className='portfolio__mobility-image-product' id='mobility-p1' />
        </div>
        <div className='portfolio__mobility-content'>
          <h3 className='gradient__text__mobility'>Goal</h3>
          <div>
            <p className='requirements_mobility' style={{textAlign: 'left'}}>Create a screendesign and click prototype for the previously developed mobility concept</p>
            <p></p>
            <p></p>
          </div>
        </div>
      </div>

      <div className='portfolio__mobility'>
        <div className='portfolio__mobility-image-product animate'>
          <img src={product_3} alt="product_2" className='portfolio__mobility-image-product' id='mobility-p1' />
        </div>

        <div className='portfolio__mobility-content-implementation distance_mobility'>
          <div>
            <p>The first part of this project consisted of researching already existing concepts of mobility. <br />
              The most commonly used mobility options are cars and public transportation like busses or metros. For this project the concept of autonomous driving cars mixed with an approach of public transportation was used. The idea was to use autonomous cars like you would use an Uber. The car can be ordered and controlled by your smartphone. <br />
              Based on this concept possible proto personas were created. Minding the needs of these personas, the content of the application was chosen and implemented into a first screen design.
            </p>
          </div>
        </div>

        <div className='portfolio__mobility-content-implementation'>
          <h3 className='gradient__text__mobility'>Implementation</h3>

          <p className='spacer_mobility'> This design then was used for the click prototype. The focus of the click prototype should be the scenario of a business man who has to go to work every morning at similar hours. The concept allows to create routines reducing the necessary interactions required by the user. <br />
            This scenario then was tested and the prototype was refined according to feedback.</p>

        </div>
      </div>

    </div>

  )

}

/* window.addEventListener('scroll', () => {
  const scrolled = window.scrollY;
  var element = document.getElementById("mobility-fg");
  var spl = document.getElementById("mobility-bg");

  if (scrolled <= 1900) {
    factor = 1;
    element.classList.remove("fade-out");
  }
  else if (scrolled > 1901) {
    element.classList.add("fade-out");
  }
}) */

export default Mobility