import React from 'react';
import './automotive.css';

import { useState, useEffect } from 'react';
import splash from '../../assets/bachelor_images/portfolio_automotive_splash.png';
import motive from '../../assets/bachelor_images/portfolio_automotive_motive.png';
import product_1 from '../../assets/bachelor_images/dataclasses_vue.png';
import product_2 from '../../assets/bachelor_images/Accident_Overview.png';
import product_3 from '../../assets/bachelor_images/ScaleMeansUEQ.png';

var factor = 1;

const Automotive = () => {
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);


  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div>
      <div className='portfolio__automotive' id='automotive'>
        <div className='portfolio__automotive-image animate'>
          <img src={splash} alt="splash" className='portfolio__automotive-image-bg' id='automotive-bg' />
          <img src={motive} alt="motive" className='portfolio__automotive-image-fg' id='automotive-fg' />
        </div>

        <div className='portfolio__automotive-content'>
          <h1 className='gradient__text__automotive'>AUTOMOTIVE DIGITAL FORENSICS</h1>
          <h2 className='gradient__text__automotive'>DATAVISUALIZATION</h2>
        </div>
      </div>

      <div className='portfolio__automotive'>
        <div className='portfolio__automotive-image-product animate'>
          <img src={product_1} alt="product_1" className='p1' id='automotive-p1' />
        </div>
        <div className='portfolio__automotive-content'>
          <h3 className='gradient__text__automotive'>Project requirements</h3>
          <div>
            <p className='requirements_automotive' style={{textAlign: 'left'}}>Find a way to visualize contextual information in the field of automotive digital forensics</p>
            <p></p>
            <p></p>
          </div>
        </div>
      </div>

      <div className='portfolio__automotive'>
        <div className='portfolio__automotive-image-product animate'>
          <img src={product_2} alt="product_2" className='p1' id='automotive-p1' />
        </div>
        <div className='portfolio__automotive-content'>
          <h3 className='gradient__text__automotive'>Goal</h3>
          <div>
            <p className='requirements_automotive' style={{textAlign: 'left'}}>Visualize data from the field of automotive digital forensics in multiple ways to compare them and highlight the advantages of UXD for digital forensics</p>
            <p></p>
            <p></p>
          </div>
        </div>
      </div>

      <div className='portfolio__automotive'>
        <div className='portfolio__automotive-image-product animate'>
          <img src={product_3} alt="product_2" className='p2' id='automotive-p1' />
        </div>

        <div className='portfolio__automotive-content-implementation distance_automotive'>
          <div>
            <p>The research question of the thesis was: “How can important information for automotive digital forensics be visualized in different contexts?”. To answer this question there were some definitions needed for what the context, important information and visualizations are. <br />
              After clarifying this, two forms of visualization were developed using the methodology of Jesse James Garret called the Elements of User Experience. <br />
              Following that, the visualizations were implemented in form of click prototypes. Now with these prototypes a visual comparison using common design guidlines like the laws of UX could be done.
            </p>
          </div>
        </div>

        <div className='portfolio__automotive-content-implementation'>
          <h3 className='gradient__text__automotive'>Implementation</h3>

          <p className='spacer_automotive'> The reason for this was to identify the more promising option that was then chosen to be further developed as a software prototype using the Vue.js framework. <br />
            The two visualization prototypes then were tested by possible users using the user experience questionnaire. The results of the questionnaire were then evaluated and sadly no significant difference could be discovered. <br />
            Nevertheless, UXD still has the potential to enhance the experience of digital forensic tools as the tools are meant to be used by a user and therefore UXD methods can be used in the development of these tools to improve them.
          </p>

        </div>
      </div>

    </div>

  )

}

/* window.addEventListener('scroll', () => {
  const scrolled = window.scrollY;
  var element = document.getElementById("automotive-fg");
  var spl = document.getElementById("automotive-bg");

  if (scrolled <= 1900) {
    factor = 1;
    element.classList.remove("fade-out");
  }
  else if (scrolled > 1901) {
    element.classList.add("fade-out");
  }
}) */

export default Automotive