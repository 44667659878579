import React from 'react';
import './boxmotion.css';

import { useState, useEffect } from 'react';
import splash from '../../assets/boxmotion/portfolio_boxmotion_splash.png';
import motive from '../../assets/boxmotion/portfolio_boxmotion_motive.png';
import product_1 from '../../assets/boxmotion_images/loginscreen_boxmotion.png';
import product_2 from '../../assets/boxmotion_images/generalinfo_boxmotion.png';
import product_3 from '../../assets/boxmotion_images/product_boxmotion.png';

const Boxmotion = () => {
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);


  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div>

      <div className='portfolio__boxmotion' id='boxmotion'>
        <div className='portfolio__boxmotion-content'>
          <h1 className='gradient__text__boxmotion'>BOXMOTION APP</h1>
          <h2 className='gradient__text__boxmotion'>APP DESIGN</h2>
        </div>
        <div className='portfolio__boxmotion-image animate'>
          <img src={splash} alt="splash" className='portfolio__boxmotion-image-bg' id='boxmotion-bg' />
          <img src={motive} alt="motive" className='portfolio__boxmotion-image-fg' id='boxmotion-fg' />
        </div>
      </div>

      <div className='portfolio__boxmotion'>
        <div className='portfolio__boxmotion-content'>
          <h3 className='gradient__text__boxmotion'>Project requirements</h3>
          <div>
            <p className='requirements_boxmotion' style={{ textAlign: 'left' }}>Based on project of students from Queensland University of Technology</p>
            <p style={{ textAlign: 'left' }}>Topic: Enhance experience of modboxing</p>
            <p style={{ textAlign: 'left' }}>Design interface for developed product for gathering information and statistical use</p>
          </div>
        </div>
        <div className='portfolio__boxmotion-image animate'>
          <img src={product_1} alt="product_1" className='portfolio__boxmotion-image-product' id='boxmotion-p1' />
        </div>
      </div>

      <div className='portfolio__boxmotion'>
        <div className='portfolio__boxmotion-content'>
          <h3 className='gradient__text__boxmotion'>Goal</h3>
          <div>
            <p style={{ textAlign: 'left' }} className='requirements_boxmotion'>Develop an interface and implement it in form of a click prototype, then present the result in a short image video</p>
            <p></p>
            <p></p>
          </div>
        </div>
        <div className='portfolio__boxmotion-image animate'>
          <img src={product_2} alt="product_2" className='portfolio__boxmotion-image-product' id='boxmotion-p1' />
        </div>
      </div>
      <div className='headline_implementation_boxmotion'>
          <h3 className='gradient__text__boxmotion'>Implementation</h3>
        </div>

      <div className='portfolio__boxmotion'>

        <div className='portfolio__boxmotion-content-implementation'>
          <div className='spacer_boxmotion'>
            <p> To start the project, a product designed by the students of Queensland University had to be chosen. Based on this product, the interface should be developed. The choice fell on a data glove that transmits 3D motion data of the athlete to the device. It can be viewed similar to the concept of 3D motion capturing. <br />
              Based on this concept personas could be established. One persona for the athlete and the other representing the coach. <br />
              Now all possible information, usable in the application were gathered and first sketches were made. The sketches were then evaluated in a feedback session as part of the seminar and afterwards refined accordingly. <br />
              With the sketches as template, first wireframes were designed in Illustrator.</p>
          </div>
        </div>

        <div className='portfolio__boxmotion-content-implementation distance_boxmotion'>
          <div>
            <p>Following the layout of the wireframes, the screen design and click prototype were created. <br />
              In the next step a scenario, where an athlete and his coach use the application was written. Based on this scenario a storyboard for the image movie was sketched. <br />
              Most of the short video was filmed in a boxing studio with only a few scenes shot outside. The final result then had to be put together using Premiere Pro and After Effects.
            </p>
          </div>
        </div>

        <div className='portfolio__boxmotion-image animate'>
          <img src={product_3} alt="product_2" className='portfolio__boxmotion-image-product' id='boxmotion-p1' />
        </div>
      </div>

    </div>

  )

}

/* window.addEventListener('scroll', () => {
  const scrolled = window.scrollY;
  var element = document.getElementById("boxmotion-fg");
  var spl = document.getElementById("boxmotion-bg");

  if (scrolled <= 1900) {
    factor = 1;
    element.classList.remove("fade-out");
  }
  else if (scrolled > 1901) {
    element.classList.add("fade-out");
  }
}) */

export default Boxmotion