import React from 'react';
import './eventeers.css';
import splash from '../../assets/event/portfolio_event_splash.png';
import motive from '../../assets/event/portfolio_event_motive.png';
import product_1 from '../../assets/eventeers_images/startscreen_phone.png';
import product_2 from '../../assets/eventeers_images/main_feed_phone.png';

const Eventeers = () => {

  return (
    <div>
      <div className='portfolio__eventeers' id='eventeers'>
        <div className='portfolio__eventeers-image animate'>
          <img src={splash} alt="splash" className='portfolio__eventeers-image-bg' id='eventeers-bg' />
          <img src={motive} alt="motive" className='portfolio__eventeers-image-fg' id='eventeers-fg' />
        </div>

        <div className='portfolio__eventeers-content'>
          <h1 className='gradient__text__eventeers'>EVENTEERS EVENT APP</h1>
          <h2 className='gradient__text__eventeers'>APP DESIGN</h2>
        </div>
      </div>

      <div className='portfolio__eventeers'>
        <div className='portfolio__eventeers-image-product animate'>
          <img src={product_1} alt="product_1" className='portfolio__eventeers-image-product' id='eventeers-p1' />
        </div>
        <div className='portfolio__eventeers-content'>
          <h3 className='gradient__text__eventeers'>Project requirements</h3>
          <div>
            <p className='requirements_eventeers' style={{ textAlign: 'left' }}>Free choice of topic</p>
            <p style={{ textAlign: 'left' }}>Development following the principles of Lean UX</p>
            <p style={{ textAlign: 'left' }}>Focus on creating ideas and reviewing them</p>
          </div>
        </div>
      </div>

      <div className='portfolio__eventeers'>
        <div className='portfolio__eventeers-image-product animate'>
          <img src={product_2} alt="product_2" className='portfolio__eventeers-image-product' id='eventeers-p1' />
        </div>
        <div className='portfolio__eventeers-content'>
          <h3 className='gradient__text__eventeers'>Goal</h3>
          <div>
            <p className='requirements_eventeers' style={{ textAlign: 'left' }}>Create a medium to high fidelity prototype</p>
            <p></p>
            <p></p>
          </div>
        </div>
      </div>
      <div className='headline_implementation_eventeers'>
        <h3 className='gradient__text__eventeers'>Implementation</h3>
      </div>
      <div className='portfolio__eventeers'>
        <div className='portfolio__eventeers-image-product animate'>
          <img src={product_2} alt="product_2" className='portfolio__eventeers-image-product' id='eventeers-p1' />
        </div>

        <div className='portfolio__eventeers-content-implementation distance_eventeers'>
          <div>
            <p>At the beginning of the project, the team discussed some possible topics. The subject that seemed the most interesting to all members was events and the access to information about them. <br />
              After choosing the topic, the Lean UX cycle was started by declaring assumptions. The main assumption, most of the development revolved around was that people are not provided with enough information about events in their area.
              To face this problem, an app should be created containing all these necessary information. <br />
              So the first iteration of the Lean UX cycle started by defining the basic content of the app and creating the first version of it in form of a click prototype.
              This click prototype was tested by the team and afterwards with real possible users. In the process of testing, feedback was collected to then be evaluated by the team.
            </p>
          </div>
        </div>

        <div className='portfolio__eventeers-content-implementation'>


          <p className='spacer_eventeers'> After concluding all feedback sessions the stated assumption was evaluated if it can be seen as verified or not. The assumption then was adapted to the feedback of the users. <br />
            Now based on this new hypothesis the click prototype was adapted, improving the already existing features or adding new ones. <br />
            Then the cycle starts again with testing. This process was repeated multiple times over the course of 6 weeks. <br />
            In the end a medium to high fidelity prototype was developed in Figma and presented to the course.</p>

        </div>

      </div>


    </div>

  )

}



/* window.addEventListener('scroll', () => {
  const scrolled = window.scrollY;
  var element = document.getElementById("eventeers-fg");
  var spl = document.getElementById("eventeers-bg");

  if (scrolled <= 1900) {
    factor = 1;
    element.classList.remove("fade-out");
  }
  else if (scrolled > 1901) {
    element.classList.add("fade-out");
  }
}) */

export default Eventeers