import React from 'react';
import Person from '@material-ui/icons/PersonOutlined';
import Home from '@material-ui/icons/HomeOutlined';
import Mail from '@material-ui/icons/EmailOutlined';

import './kontakt.css';
const Kontakt = () => {

  function sendEmail() {
    window.location = "mailto:julian.gaensbauer@outlook.de";
  };

  return (
    <div>
      <div className='portfolio__contact' id='contact'>
        <div className='portfolio__contact-content'>
          <h3 className='gradient__text__contact'>CONTACT ME</h3>
          <div className='requirements_contact'>


            <p><Person style={{ color: 'white', width: '46px', height: 'auto', marginRight: '50px' }}></Person>Julian Gänsbauer</p>


            <p><Home style={{ color: 'white', width: 'auto', height: '46px', marginRight: '50px' }}></Home>Ingolstadt, Germany</p>


            <p style={{ cursor: 'pointer' }} onClick={sendEmail}><Mail style={{ color: 'white', width: '44px', height: 'auto', marginRight: '50px' }}></Mail>julian.gaensbauer@outlook.de</p>
            <a href="https://in.linkedin.com/in/julian-gänsbauer-438165187">
              <p><svg style={{marginRight: '45px'}}width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5 0C2.26563 0 0 2.26563 0 5V39C0 41.7344 2.26563 44 5 44H39C41.7344 44 44 41.7344 44 39V5C44 2.26563 41.7344 0 39 0H5ZM5 4H39C39.5703 4 40 4.42969 40 5V39C40 39.5703 39.5703 40 39 40H5C4.42969 40 4 39.5703 4 39V5C4 4.42969 4.42969 4 5 4ZM10.875 7.4375C8.97656 7.4375 7.4375 8.97656 7.4375 10.875C7.4375 12.7734 8.97656 14.3125 10.875 14.3125C12.7734 14.3125 14.3125 12.7734 14.3125 10.875C14.3125 8.97656 12.7734 7.4375 10.875 7.4375ZM28.9375 16.5625C26.0703 16.5625 24.1641 18.1328 23.375 19.625H23.25V17H17.625V36H23.5V26.625C23.5 24.1484 23.9922 21.75 27.0625 21.75C30.0859 21.75 30.125 24.5469 30.125 26.75V36H36V25.5625C36 20.4531 34.9141 16.5625 28.9375 16.5625ZM8 17V36H13.9375V17H8Z" fill="white" />
              </svg>
              Julian Gänsbauer
              </p>
            </a>
          </div>
        </div>
        <div className='portfolio__boxmotion-image animate'>
          <svg
            className='logo-content'
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 150 150">
            <g id="a" /><g id="b" />
            <g id="c" /><g id="d" />
            <g id="e" /><g id="f" />
            <g id="g" /><g id="h"><g>
              <polygon
                points="74.99 54.97 74.99 64.68 100.67 64.68 101.77 64.68 108.88 64.68 108.88 54.97 74.99 54.97" />
              <polygon
                points="98.42 70.94 98.42 88.32 98.42 97.74 98.42 108.99 108.88 108.99 108.88 94.8 108.88 70.94 98.47 70.94 98.42 70.94" />
              <path
                d="M94.38,122.42c-2.66,2.71-6.14,4.07-10.44,4.07-4.7,0-8.29-1.26-10.76-3.8-2.48-2.53-3.71-6.05-3.71-10.57h-10.41c0,7.23,2.19,12.85,6.56,16.86,4.37,4.01,10.48,6.02,18.33,6.02s13.6-2.13,18.14-6.4c3.62-3.4,5.79-7.85,6.52-13.35h-10.59c-.58,2.91-1.79,5.3-3.63,7.17Z" />
              <path
                d="M92.16,98.78v-8.78c-2.21,.32-4.85,.5-7.91,.5-17.4,0-29.38-10.12-31.52-27.55h-11.36c1.04,10.58,5.15,19.56,11.26,25.46,8.09,7.72,18.39,10.91,30.89,10.91,3.03,0,5.93-.21,8.65-.55Z" />
              <path
                d="M85.6,23.95c8.21,0,13.68,1.55,18.06,3.46v-9.73c-4.05-1.39-10.28-2.68-17.81-2.68-27.34,0-44.17,17.55-44.72,41.7h11.27c.2-20.43,13.26-32.75,33.21-32.75Z" />
            </g>
            </g>
            <g id="i" />
          </svg>
        </div>

      </div>
    </div>
  )
}

export default Kontakt