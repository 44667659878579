import { useEffect, useState } from "react";
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

export const ScrollToTop = () => {
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
        if(window.pageYOffset > 4000){
            document.getElementById("toTop").style.opacity="100%";
        } else {
            document.getElementById("toTop").style.opacity="0%";
        }
    };

    const scrollToTop = () => {
        var element = document.getElementById('overview');
        element.scrollIntoView({            
            behavior: 'smooth',
        });
    }

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);

        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        }
    }, []);

    return (
        <div>
            <button 
            id="toTop"
            onClick={scrollToTop} 
            style={{
                backgroundImage: 'linear-gradient(233.73deg, #DF8C41 14.24%, #D753A2 108.07%)',
                borderRadius: '100%',
                border: '0',
                boxShadow: 'none',
                position: 'fixed',
                bottom: '0',
                right: '0',
                width: '50px',
                height: '50px',
                margin: '50px',
                opacity: '0%'
                }}>
            <KeyboardArrowUpIcon style={{color: 'white', width: '40px', height: '40px'}}></KeyboardArrowUpIcon>
            </button>
        </div>
    )
}