import React from 'react';
import { Boxmotion, Eventeers, Automotive, Food, Kontakt, Light, Mobility, Motivation } from './containers';
import { ScrollToTop, Landing } from './components';
import './App.css';


function App() {

  return (
    <div className="App">
      <div className='mobile_disclaimer'>
        <h1>Mobile version under construction...coming soon!</h1>
      </div>
      <div className='app__content'>
        <div className="gradient__bg">
          <Landing />
        </div>
        <Eventeers />
        <Boxmotion />
        <Mobility />
        <Light />
        <Motivation />
        <Food />
        <Automotive />
        <Kontakt />
        <ScrollToTop />
      </div>
    </div>
  )
}

export default App