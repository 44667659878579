import React from 'react';
import './light.css';

import { useState, useEffect } from 'react';
import splash from '../../assets/lampe/portfolio_lamp_splash.png';
import motive from '../../assets/lampe/portfolio_lamp_motive.png';
import product_1 from '../../assets/lampe_images/lamp_diagonal_nobg_edit.png';
import product_2 from '../../assets/lampe_images/lamp_nobg_edit.png';
import product_3 from '../../assets/lampe_images/Skizze2_wowhite.png';

const Light = () => {
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);


  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div>

      <div className='portfolio__light' id='light'>
        <div className='portfolio__light-content'>
          <h1 className='gradient__text__light'>INTERACTIVE LIGHT</h1>
          <h2 className='gradient__text__light'>PRODUCT DESIGN</h2>
        </div>
        <div className='portfolio__light-image animate'>
          <img src={splash} alt="splash" className='portfolio__light-image-bg' id='light-bg' />
          <img src={motive} alt="motive" className='portfolio__light-image-fg' id='light-fg' />
        </div>
      </div>

      <div className='portfolio__light'>
        <div className='portfolio__light-content'>
          <h3 className='gradient__text__light'>Project requirements</h3>
          <div>
            <p className='requirements_light' style={{textAlign: 'left'}}>Develop a physical product</p>
            <p style={{textAlign: 'left'}}>User can interact with product</p>
            <p></p>
          </div>
        </div>
        <div className='portfolio__light-image animate'>
          <img src={product_1} alt="product_1" className='portfolio__light-image-product' id='light-p1' />
        </div>
      </div>

      <div className='portfolio__light'>
        <div className='portfolio__light-content'>
          <h3 className='gradient__text__light'>Goal</h3>
          <div>
            <p style={{textAlign: 'left'}} className='requirements_light'>Create a physical product representing a combination of interactive technology and aesthetic product design</p>
            <p></p>
            <p></p>
          </div>
        </div>
        <div className='portfolio__light-image animate'>
          <img src={product_2} alt="product_2" className='portfolio__light-image-product' id='light-p1' />
        </div>
      </div>

      <div className='portfolio__light'>
        <div className='portfolio__light-content-implementation'>
          <h3 className='gradient__text__light'>Implementation</h3>
          <div className='spacer_light'>
            <p> The development of this product followed, more or less, the double diamond principle. In the beginning, a lot of ideas were collected and sketched. These ideas were then reduced to the most promising ones. The remaining concept sketches had to be refined and new sketches with different designs of the idea were created. Of these sketches now the final idea for the project had to be chosen. In this case the choice was an interactive light. <br />
              After choosing the topic, 3D models of different design options had to be created. Of these 3D models now one was selected. <br />
              </p>
          </div>
        </div>

        <div className='portfolio__light-content-implementation distance_light'>
          <div>
            <p>With the design in mind, ideas for the implementation of the technology were collected. <br /> The most promising option that was used in the end was a programmable LED strip controlled by an arduino using the input of a motion sensor. <br />
              Then the physical implemenation of the lamp design was started. Multiple hexagons were cut from wooden boards and afterwards joined using epoxy resin. <br />
              Now all that was left, was to attach the technology part to the design.
            </p>
          </div>
        </div>

        <div className='portfolio__light-image animate'>
          <img src={product_3} alt="product_2" className='portfolio__light-image-product' id='light-p1' />
        </div>
      </div>

    </div>

  )

}

/* window.addEventListener('scroll', () => {
  const scrolled = window.scrollY;
  var element = document.getElementById("light-fg");
  var spl = document.getElementById("light-bg");

  if (scrolled <= 1900) {
    factor = 1;
    element.classList.remove("fade-out");
  }
  else if (scrolled > 1901) {
    element.classList.add("fade-out");
  }
}) */

export default Light